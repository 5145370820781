import './App.css';
import { useState } from 'react';

function App() {
  const handleWaitlistClick = () => {
    window.open('https://forms.gle/r4snSUva9t5FzEvD9', '_blank');
  };

  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const handleTermsClick = () => {
    setShowTerms(true);
    setShowPrivacy(false);
  };

  const handlePrivacyClick = () => {
    setShowPrivacy(true);
    setShowTerms(false);
  };

  const handleBack = () => {
    setShowTerms(false);
    setShowPrivacy(false);
  };

  if (showTerms) {
    return (
      <div className="App">
        <header className="App-header">
          <button className="back-button" onClick={handleBack}>← Back</button>
          <div className="legal-content" style={{ marginTop: '2rem' }}>
            <h1>Terms of Service</h1>
            <p>
              By submitting your email address through this website, you agree to join the waitlist for Whale Card. 
              Joining the waitlist does not guarantee access to the product or its availability. 
              Your email will be used solely to contact you about the product's waitlist status, updates, 
              and potential availability. If you wish to remove yourself from the waitlist, 
              please contact us at james@whalecard.xyz.
            </p>
          </div>
        </header>
      </div>
    );
  }

  if (showPrivacy) {
    return (
      <div className="App">
        <header className="App-header">
          <button className="back-button" onClick={handleBack}>← Back</button>
          <div className="legal-content" style={{ marginTop: '2rem' }}>
            <h1>Privacy Policy</h1>
            <p>
              We collect your email address to manage the waitlist for Whalecard. Your email will only 
              be used to notify you about the product's waitlist status, updates, and availability. 
              We do not share or sell your data to third parties. Data is securely stored using 
              Google Forms and will be deleted upon request or at the end of the waitlist period. 
              For any questions or to request data deletion, contact us at james@whalecard.xyz.
            </p>
          </div>
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Whale Card</h1>
        <div className="card-visual">
          <div className="debit-card">
            <img 
              src="/chip.png" 
              alt="Card Chip"
              className="card-chip"
            />
            <img 
              src="/whale-logo.svg" 
              alt="Whale Card Logo" 
              className="whale-logo" 
            />
          </div>
        </div>
        <p className="card-tagline">
          <span className="tagline-main">The 2% cashback debit card.</span><br/>
          <span className="tagline-features">
            <span className="no-wrap">No fees. No minimums.</span><br className="mobile-only"/>
            <span className="no-wrap">No compromises.</span>
          </span>
        </p>
        <button className="waitlist-button" onClick={handleWaitlistClick}>
          Join the Waitlist
        </button>
      </header>
      <div className="footer-links">
        <button className="link-button" onClick={handleTermsClick}>Terms</button>
        <span className="separator">|</span>
        <button className="link-button" onClick={handlePrivacyClick}>Privacy</button>
      </div>
    </div>
  );
}

export default App;
